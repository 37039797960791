<section class="section-dashboard">
  <div class="dashboard-box">
    <!-- step--1 -->
    <div class="dashboard-title-box">
      <p class="dashboard-title-box__title">Risk dashboard</p>
    </div>

    <!-- step--2 -->

    <div class="dashboard-main-content">
      <div class="dashboard-content-container">
        <!-- step-box-1 -->

        <div class="dashboard-img-box-grid-container">
          <!-- item-1 -->
          <div class="dashboard-item-box">
            <div class="dashboard-item-box-title">
              <p class="dashboard-item-box-title__title">
                Current Risk profile
              </p>
            </div>

            <highcharts-chart
              *ngIf="risk_profile_current_chartOptions"
              [Highcharts]="Highcharts"
              [options]="risk_profile_current_chartOptions"
            >
            </highcharts-chart>
          </div>

          <!-- item-2 -->
          <div class="dashboard-item-box">
            <div class="dashboard-item-box-title">
              <p class="dashboard-item-box-title__title">Target Risk profile</p>
            </div>
            <highcharts-chart
              *ngIf="risk_profile_target_chartOptions"
              [Highcharts]="Highcharts"
              [options]="risk_profile_target_chartOptions"
            >
            </highcharts-chart>
          </div>

          <!-- item-3 -->
          <div class="dashboard-item-box">
            <div class="dashboard-item-box-title">
              <p class="dashboard-item-box-title__title">Status of action</p>
            </div>
            <highcharts-chart
              *ngIf="status_of_action_chartOptions"
              [Highcharts]="Highcharts"
              [options]="status_of_action_chartOptions"
            >
            </highcharts-chart>
          </div>

          <!-- item-4 -->
          <div class="dashboard-item-box">
            <div class="dashboard-item-box-title">
              <p class="dashboard-item-box-title__title">Risk Categories</p>
            </div>

            <highcharts-chart
              *ngIf="categories_count_chartOptions"
              [Highcharts]="Highcharts"
              [options]="categories_count_chartOptions"
            >
            </highcharts-chart>
          </div>

          <!-- item-5 -->
          <div class="dashboard-item-box">
            <div class="dashboard-item-box-title">
              <p class="dashboard-item-box-title__title">Categories groups</p>
            </div>

            <highcharts-chart
              *ngIf="category_group_chartOptions"
              [Highcharts]="Highcharts"
              [options]="category_group_chartOptions"
            >
            </highcharts-chart>
          </div>

          <!-- item-6 -->
          <div class="dashboard-item-box">
            <div class="dashboard-item-box-title">
              <p class="dashboard-item-box-title__title">Action Areas</p>
            </div>

            <highcharts-chart
              *ngIf="action_areas_chartOptions"
              [Highcharts]="Highcharts"
              [options]="action_areas_chartOptions"
            >
            </highcharts-chart>
          </div>
        </div>

        <div class="dashboard-title-table-box">
          <p class="dashboard-title-table-box__title">Risk details</p>
        </div>

        <!-- Risk details-table -->
        <div class="table-box mat-elevation-z8">
          <mat-card>
            <!-- <mat-card-title style="text-align: center;">Initiative by risk score</mat-card-title> -->
            <mat-card-content>
              <table>
                <tr>
                  <th>INIT-ID</th>
                  <th>Initiative</th>
                  <th>Risk Category</th>
                  <th>Risk</th>
                  <th>Risk Description</th>
                  <th>Risk score</th>
                </tr>
                <ng-container *ngFor="let initiative of details">
                  <tr *ngFor="let risk of initiative.risks; let i = index">
                    <td
                      *ngIf="i == 0"
                      [attr.rowspan]="initiative.risks.length"
                      style="min-width: 60px"
                    >
                      {{ initiative.official_code }}
                    </td>
                    <td *ngIf="i == 0" [attr.rowspan]="initiative.risks.length">
                      {{ initiative.name }}
                    </td>
                    <td>{{ risk.category.title }}</td>
                    <td>{{ risk.title }}</td>
                    <td>{{ risk.description }}</td>
                    <td class="level" [attr.style]="color(risk.current_level)">
                      {{ risk.current_level }}
                    </td>
                  </tr>
                </ng-container>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</section>
