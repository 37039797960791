<!-- user-management-content -->

<div class="user-management__icon-box">
  <mat-icon class="user-management__icon-box__icon">
    <svg
      class="cc"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="30"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M6.5 11L12 2l5.5 9h-11Zm11 11q-1.875 0-3.188-1.313T13 17.5q0-1.875 1.313-3.188T17.5 13q1.875 0 3.188 1.313T22 17.5q0 1.875-1.313 3.188T17.5 22ZM3 21.5v-8h8v8H3Z  "
        fill="black"
      /></svg
  ></mat-icon>
  <span class="user-management__icon-box__title">Categories</span>
</div>

<div class="user-management__text-box--1">
  <p class="user-management__text-box__title">
    Welcome to the categories panel, here you can manage the category table within the platform.
  </p>
</div>

<div class="user-management__content--1">
  <div class="user-management__container">
    <div class="form-container">
      <form *ngIf="filterForm" [formGroup]="filterForm" class="search">
        <div class="sec-1">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Search
            </mat-label>
            <input
              formControlName="title"
              class="search__input"
              matInput
              placeholder="Search"
              #input
            />

            <button
              style="border: none; cursor: pointer; background-color: #ffffff12"
              class="search__button"
            >
              <mat-icon class="search__icon">
                <img
                  class="search__icon"
                  src="../../assets/shared-image/Vector-search.svg"
                  alt="~/Frontend/images/icon _ search-icon"
              /></mat-icon>
            </button>
          </div>
        </div>

        <!-- //Sort----- -->
        <div class="sec-6">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Sort</mat-label
            >

            <mat-select
              formControlName="sort"
              class="risk-management-form-field-box__input-3"
              [placeholder]="'All'"
              id="select-where"
            >
              <mat-option>All</mat-option>
              <mat-option *ngFor="let item of sort" [value]="item.value">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- //Reset Button----- -->
        <div class="sec-9">
          <button
            class="risk-management-reset-btn"
            mat-raised-button
            (click)="resetForm()"
          >
            Reset
          </button>
        </div>

        <div class="sec-7">
          <button
            class="parameters-settings__button-box__btn btn-primary-size"
            (click)="openDialogCreateCategory('Add Category', null)"
            mat-raised-button
            color="primary"
          >
            Create category
          </button>
          <button
            class="user-management__button-box__btn btn-export-size"
            mat-raised-button
            color="primary"
            (click)="export()"
          >
            <mat-icon class="user-management__button-box__icon">
              <svg
                class="risk-management-export-box-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
              >
                <path
                  d="M5.65951 17.6484C5.15534 17.6484 4.72389 17.4772 4.36517 17.1348C4.00584 16.7918 3.82617 16.3797 3.82617 15.8984V13.2734H5.65951V15.8984H16.6595V13.2734H18.4928V15.8984C18.4928 16.3797 18.3135 16.7918 17.9548 17.1348C17.5954 17.4772 17.1637 17.6484 16.6595 17.6484H5.65951ZM11.1595 14.1484L6.57617 9.77344L7.8595 8.50469L10.2428 10.7797V3.64844H12.0762V10.7797L14.4595 8.50469L15.7428 9.77344L11.1595 14.1484Z"
                  fill="white"
                />
              </svg>
            </mat-icon>
            <span class="user-management__button-box__title">Export data</span>
          </button>
        </div>
      </form>
    </div>

    <div class="table-box mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h1">ID</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-3">Category</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="td-3-h">{{ element.title }}</span>
          </td>
        </ng-container>

        <!-- Category group Column -->
        <ng-container matColumnDef="category_group_name">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h3">Category group</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="td-h">{{ element.category_group.name }}</span>
          </td>
        </ng-container>

        <!-- Description  Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h4">Description</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.description }}
          </td>
        </ng-container>

        <!-- Action Name Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h5">Actions</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="m">
              <button
                matTooltip="Edit"
                style="border: none; cursor: pointer; background-color: #ffffff"
                (click)="openDialogEditCategory('Edit Category', element)"
              >
                <mat-icon class="user-management-table__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                  >
                    <path
                      d="M17.6917 7.80937L13.7958 4.13437L15.0792 2.90938C15.4306 2.57396 15.8623 2.40625 16.3744 2.40625C16.8859 2.40625 17.3174 2.57396 17.6687 2.90938L18.9521 4.13437C19.3035 4.46979 19.4868 4.87462 19.5021 5.34887C19.5174 5.82254 19.3493 6.22708 18.9979 6.5625L17.6917 7.80937ZM16.3625 9.1L6.64583 18.375H2.75V14.6562L12.4667 5.38125L16.3625 9.1Z"
                      fill="#04030F"
                    /></svg
                ></mat-icon>
              </button>

              <button
                style="border: none; cursor: pointer; background-color: #ffffff"
              >
                <mat-icon
                  matTooltip="Enabled category"
                  *ngIf="element.disabled == 0"
                  (click)="onViewClick(element)"
                  >visibility</mat-icon
                >
                <mat-icon
                  matTooltip="Disabled category"
                  *ngIf="element.disabled == 1"
                  (click)="onViewClick(element)"
                  >block</mat-icon
                >
                <!-- <mat-icon class="user-management-table__icon">
                  <img
                    style="width: 20px; height: 20px"
                    class="table__icon"
                    src="../../../../../assets/shared-image/view-filled.svg"
                    alt="~/Frontend/images/icon _ Vector-edit"
                /></mat-icon>
                 -->
              </button>

              <button
                matTooltip="Delete"
                style="border: none; cursor: pointer; background-color: #ffffff"
                (click)="deleteCategoryById(element.id)"
              >
                <mat-icon class="user-management-table__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      d="M17.7188 3.9375H14.7656V2.95312C14.7635 2.34462 14.5208 1.76166 14.0905 1.33138C13.6602 0.901097 13.0773 0.658412 12.4688 0.65625H8.53125C7.92275 0.658412 7.33978 0.901097 6.9095 1.33138C6.47922 1.76166 6.23654 2.34462 6.23438 2.95312V3.9375H3.28125C3.02018 3.9375 2.7698 4.04121 2.58519 4.22582C2.40059 4.41042 2.29688 4.6608 2.29688 4.92188C2.29688 5.18295 2.40059 5.43333 2.58519 5.61793C2.7698 5.80254 3.02018 5.90625 3.28125 5.90625H3.60938V17.0625C3.61153 17.497 3.78507 17.913 4.09229 18.2202C4.3995 18.5274 4.81554 18.701 5.25 18.7031H15.75C16.1845 18.701 16.6005 18.5274 16.9077 18.2202C17.2149 17.913 17.3885 17.497 17.3906 17.0625V5.90625H17.7188C17.9798 5.90625 18.2302 5.80254 18.4148 5.61793C18.5994 5.43333 18.7031 5.18295 18.7031 4.92188C18.7031 4.6608 18.5994 4.41042 18.4148 4.22582C18.2302 4.04121 17.9798 3.9375 17.7188 3.9375ZM8.20312 2.95312C8.20312 2.8661 8.2377 2.78264 8.29923 2.72111C8.36077 2.65957 8.44423 2.625 8.53125 2.625H12.4688C12.5558 2.625 12.6392 2.65957 12.7008 2.72111C12.7623 2.78264 12.7969 2.8661 12.7969 2.95312V3.9375H8.20312V2.95312ZM15.4219 16.7344H5.57812V5.90625H15.4219V16.7344ZM9.51562 8.53125V13.7812C9.51562 14.0423 9.41191 14.2927 9.22731 14.4773C9.0427 14.6619 8.79232 14.7656 8.53125 14.7656C8.27018 14.7656 8.0198 14.6619 7.83519 14.4773C7.65059 14.2927 7.54688 14.0423 7.54688 13.7812V8.53125C7.54688 8.27018 7.65059 8.0198 7.83519 7.83519C8.0198 7.65059 8.27018 7.54688 8.53125 7.54688C8.79232 7.54688 9.0427 7.65059 9.22731 7.83519C9.41191 8.0198 9.51562 8.27018 9.51562 8.53125ZM13.4531 8.53125V13.7812C13.4531 14.0423 13.3494 14.2927 13.1648 14.4773C12.9802 14.6619 12.7298 14.7656 12.4688 14.7656C12.2077 14.7656 11.9573 14.6619 11.7727 14.4773C11.5881 14.2927 11.4844 14.0423 11.4844 13.7812V8.53125C11.4844 8.27018 11.5881 8.0198 11.7727 7.83519C11.9573 7.65059 12.2077 7.54688 12.4688 7.54688C12.7298 7.54688 12.9802 7.65059 13.1648 7.83519C13.3494 8.0198 13.4531 8.27018 13.4531 8.53125Z"
                      fill="#04030F"
                    /></svg
                ></mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr
          class="table-header"
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          class="table-header"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        class="glossary-content-container user-management-paginator-box"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [length]="length"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex - 1"
        (page)="pagination($event)"
        aria-label="Select page of users"
      >
      </mat-paginator>
    </div>
  </div>
</div>
