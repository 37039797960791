<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ data.role == "add" ? "Add New" : "Update" }} Team Member
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-conten-box-1">
  <form *ngIf="memberForm" [formGroup]="memberForm" class="form-dialog-content">
    <!-- //search-by-Email filed----- -->

    <div
      class="form-dialog-content__field--grid-column"
      *ngIf="
        (this.data?.role == 'edit' && this.data?.member?.user !== null) ||
        (memberForm.controls['email']?.value == '' && this.data?.role == 'add')
      "
    >
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Select user</mat-label
        >
      </div>
      <ng-select
        [items]="people$ | async"
        bindLabel="full_name"
        bindValue="id"
        [hideSelected]="true"
        [compareWith]="compareWith"
        [minTermLength]="2"
        formControlName="user_id"
        [loading]="peopleLoading"
        typeToSearchText="Please enter 2 or more characters to search"
        [typeahead]="peopleInput$"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-search="searchTerm"
          let-index="index"
        >
          <div class="ng-select-option-flex">
            <span>{{ item.full_name }}</span> <span>({{ item.email }})</span>
          </div>
        </ng-template>
      </ng-select>
    </div>

    <!-- //Email filed----- -->
    <ng-container *ngIf="memberForm.controls['user_id']?.value == null">
      <div class="form-dialog-content__field--grid-column">
        <div class="form-dialog-content__title-box">
          <mat-label class="form-dialog-content__title-box__title"
            >Or invite by email</mat-label
          >
        </div>
        <mat-form-field
          class="form-dialog-content__field"
          floatLabel="always"
          hideRequiredMarker
          appearance="outline"
        >
          <!-- value="{{ emailCtrl.value?.email }}" -->
          <input matInput formControlName="email" placeholder="Email" />
          <mat-error
            class="error"
            *ngIf="memberForm.controls['email'].errors?.['email']"
            >Wrong Email format</mat-error
          >
        </mat-form-field>
      </div>
    </ng-container>

    <div class="form-dialog-conten-box-2">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >which role will be assigned?<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <mat-select placeholder="Role" formControlName="userRole">
          <mat-option *ngFor="let role of Roles" [value]="role.value">
            {{ role.viewValue }}
          </mat-option>
        </mat-select>
        <mat-error
          class="error"
          *ngIf="memberForm.controls['userRole'].errors?.['required']"
          >User Role is required</mat-error
        >
      </mat-form-field>
    </div>
    <div>
      <!-- //Update Button----- -->
      <div class="form-dialog-conten-button-box">
        <div class="form-dialog-conten__button-box">
          <mat-error
            class="error"
            *ngIf="memberForm?.errors?.['atLeastOneRequired'] && showerror"
            >Email or User must be not empty</mat-error
          >
          <button
            class="form-dialog-conten__button-box__btn"
            type="submit"
            mat-raised-button
            (click)="submit()"
          >
          {{ data.role == "add" ? "Add" : "Update" }}  
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
