<mat-dialog-content class="dialog">
<div mat-dialog-container class="submit-box">
  <div class="form-dialog-header">
    <div class="form-dialog-header__text-box">
      <p class="form-dialog-header__text-box__text">Submit Risks</p>
    </div>

    <div class="form-dialog-header__icon-box">
      <button
        class="form-dialog-header__icon-box__btn"
        (click)="onCloseDialog()"
      >
        <mat-icon class="form-dialog-header__icon-box__icon-size">
          <img
            class="form-dialog-header__icon-box__icon-size__icon"
            src="../../../assets/shared-image/Vector-close.svg"
            alt="~/Frontend/images/icon _ Vector-close"
          />
        </mat-icon>
      </button>
    </div>
  </div>

  <div
    class="form-dialog-content"
    *ngIf="this.tops?.top?.length + this.tops?.similar?.length > 5"
  >
    <!-- <ng-container> -->
    <div class="submit-risk-box">
      <h2 class="submit-risk-title-1">Top 5 Risks</h2>
      <p class="submit-risk-text">
        Please note that the system selects Top 5 risks out of current risk
        level score (specified in brackets). Make sure that the submitted data
        is accurate since no further changes can be made after submission.
      </p>

      <div
        cdkDropList
        #todoList="cdkDropList"
        [cdkDropListData]="top"
        [cdkDropListConnectedTo]="[doneList]"
        class="submit-risk-drop-box"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="evenPredicate"
      >
        <div class="submit-risk-drop-text-box">
          <p class="submit-risk-drop-box__text" *ngIf="top.length == 0">
            Drop the risk here
          </p>
          <div
            class="drag-box drag-text-flex"
            *ngFor="let item of top; let i = index"
            [attr.data-index]="i"
            cdkDrag
          >
            Risk <span class="index">#{{ i + 1 }} </span>
            <div class="drag-text-flex">
              <p class="submit-risk-drag-text-2">
                {{ item.title }}
                <span class="ww">({{ item.current_level }})</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="submit-risk-flex-box">
      <div class="submit-risk-flex-x">
        <div>
          <h2 class="submit-risk-title-1">Risks selection</h2>
          <p class="submit-risk-text">
            To select please drag and drop risks from the area below
          </p>
        </div>

        <div class="note-p-t-l">
          <!-- <svg
            class="risk-report-form-note-content-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
              fill="#2196F3"
            />
            <path
              d="M9.95227 9.95312H12.0475V15.715H9.95227V9.95312Z"
              fill="white"
            />
            <path
              d="M11.0002 8.38467C11.7234 8.38467 12.3097 7.79838 12.3097 7.07515C12.3097 6.35192 11.7234 5.76562 11.0002 5.76562C10.277 5.76562 9.69067 6.35192 9.69067 7.07515C9.69067 7.79838 10.277 8.38467 11.0002 8.38467Z"
              fill="white"
            />
          </svg> -->
        </div>
      </div>
      <div
        cdkDropList
        #doneList="cdkDropList"
        [cdkDropListData]="similar"
        [cdkDropListConnectedTo]="[todoList]"
        class="example-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div class="scroll">
          <div class="qq">
            <div class="example-box" *ngFor="let item of similar" cdkDrag>
              <div class="submit-risk-drag-flex-box">
                <div class="example-text-flex">
                  <p class="submit-risk-drag-text">
                    {{ item.title }}
                    <span class="submit-risk-drag-text">
                      ({{ item.current_level }})
                    </span>
                  </p>
                </div>

                <div class="submit-risk-drag-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="15"
                    viewBox="0 0 9 15"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.6 1.59545C3.6 0.715909 2.7936 0 1.8 0C0.8064 0 0 0.715909 0 1.59545C0 2.47636 0.8064 3.19091 1.8 3.19091C2.7936 3.19091 3.6 2.47636 3.6 1.59545ZM3.6 13.4045C3.6 12.5236 2.7936 11.8091 1.8 11.8091C0.8064 11.8091 0 12.5236 0 13.4045C0 14.2841 0.8064 15 1.8 15C2.7936 15 3.6 14.2841 3.6 13.4045ZM1.8 5.90455C2.7936 5.90455 3.6 6.62045 3.6 7.5C3.6 8.37955 2.7936 9.09546 1.8 9.09546C0.8064 9.09546 0 8.37955 0 7.5C0 6.62045 0.8064 5.90455 1.8 5.90455ZM9 1.59545C9 0.715909 8.1936 0 7.2 0C6.2064 0 5.4 0.715909 5.4 1.59545C5.4 2.47636 6.2064 3.19091 7.2 3.19091C8.1936 3.19091 9 2.47636 9 1.59545ZM7.2 11.8091C8.1936 11.8091 9 12.5236 9 13.4045C9 14.2841 8.1936 15 7.2 15C6.2064 15 5.4 14.2841 5.4 13.4045C5.4 12.5236 6.2064 11.8091 7.2 11.8091ZM9 7.5C9 6.62045 8.1936 5.90455 7.2 5.90455C6.2064 5.90455 5.4 6.62045 5.4 7.5C5.4 8.37955 6.2064 9.09546 7.2 9.09546C8.1936 9.09546 9 8.37955 9 7.5Z"
                      fill="#808080"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngFor="let errorMsg of error">
        <p class="error">{{ errorMsg }}</p>
      </ng-container>

      <div class="form-dialog-conten-button-box-2">
        <div class="form-dialog-conten-button-flex">
          <div class="form-dialog-conten__button-box">
            <button
              class="form-dialog-conten__button-box__btn--send"
              type="submit"
              mat-raised-button
              (click)="publish()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="form-dialog-content"
    *ngIf="this.tops?.top?.length + this.tops?.similar?.length <= 5"
  >
    <!-- <ng-container> -->
    <div class="submit-risk-box">
      <h2 class="submit-risk-title-1">Top 5 Risks</h2>
      <p class="submit-risk-text">
        Please note that the system selects Top 5 risks out of current risk
        level score (specified in brackets). Make sure that the submitted data
        is accurate since no further changes can be made after submission.
      </p>

      <div
        cdkDropList
        #todoList="cdkDropList"
        [cdkDropListData]="top"
        class="submit-risk-drop-box"
        (cdkDropListDropped)="drop($event)"
        [cdkDropListEnterPredicate]="evenPredicate"
      >
        <div class="submit-risk-drop-text-box">
          <p class="submit-risk-drop-box__text" *ngIf="top.length == 0">
            Drop the risk here
          </p>
          <div
            class="drag-box drag-text-flex"
            *ngFor="let item of top; let i = index"
            [attr.data-index]="i"
            cdkDrag
          >
            Risk <span class="index">#{{ i + 1 }} </span>
            <div class="drag-text-flex">
              <p class="submit-risk-drag-text-2">
                {{ item.title }}
                <span class="ww">({{ item.current_level }})</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="submit-risk-flex-box">
      <div class="form-dialog-conten-button-box-2">
        <div class="form-dialog-conten-button-flex">
          <div class="form-dialog-conten__button-box">
            <button
              class="form-dialog-conten__button-box__btn--send"
              type="submit"
              mat-raised-button
              (click)="publish()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</mat-dialog-content>