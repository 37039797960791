<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ data?.action == "Edit" ? "Edit" : "New" }} FAQ
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-content">
  <form *ngIf="Form" [formGroup]="Form" class="form-dialog-content">
    <!-- //Question filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Question<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <textarea matInput formControlName="question"  placeholder="Question"></textarea>
        <mat-error
        class="error"
        *ngIf="Form.controls?.question?.errors?.['required']"
        >Question is required</mat-error
      >
      </mat-form-field>
    </div>


    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Answer<span class="star">*</span></mat-label
        >
      </div>

      <div class="NgxEditor__Wrapper">
        <!-- <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"> </ngx-editor-menu>
        <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" [placeholder]="'Type here...'" formControlName="answer"></ngx-editor> -->
        <mat-spinner *ngIf="(editorSubject | async) === null" class="spinner"></mat-spinner>
        <editor 
          (onInit)="onEditorInit($event)"
          formControlName="answer"
          [init]="settings">
        </editor>
      </div>
      <mat-error
      class="error"
      *ngIf="Form.controls?.answer?.errors?.['required'] && Form.controls.answer.touched"
      >Answer is required</mat-error
      >
    </div>


    <!-- //Update Button----- -->
    <div class="form-dialog-conten-button-box-2">
      <div class="form-dialog-conten-button-flex">

        <div class="form-dialog-conten-button-box">
          <div class="form-dialog-conten__button-box">
            <button
              class="form-dialog-conten__button-box__btn"
              type="submit"
              mat-raised-button
              (click)="onSubmit()"
            >
            Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
