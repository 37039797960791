import { Component } from '@angular/core';

@Component({
  selector: 'app-submitted-versions',
  templateUrl: './published-versions.component.html',
  styleUrls: ['./published-versions.component.scss'],
})
export class PublishedVersionsComponent {

}
