<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      Actions /Controls to manage risks
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-content">
  <form
    [formGroup]="proposedForm"
    class="form-dialog-content--actions-controls"
    (ngSubmit)="submit()"
  >
    <!-- //Actions/Controls description* filed----- -->

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Actions/Controls description<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <textarea
          rows="4"
          placeholder="Description"
          matInput
          formControlName="description"
          style="height: 10.8rem; resize: none"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="10"
        ></textarea>
        <mat-error
          class="error"
          *ngIf="proposedForm.controls['description'].errors?.['required']"
          >Action description is required</mat-error
        >
        <mat-error
          class="error"
          *ngIf="proposedForm.controls?.description?.errors?.maxword"
          >Max words you can use is
          {{ proposedForm.controls?.description?.errors?.maxword?.max }} you
          used
          {{
            proposedForm.controls?.description?.errors?.maxword.actual
          }}</mat-error
        >
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Status<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <mat-select formControlName="mitigation_status_id">
          <mat-option *ngFor="let action of Actions" [value]="action.id">
            {{ action.title }}
            <mat-icon
              matTooltip="{{ action.description }}"
              [matTooltipPosition]="'left'"
              matSuffix
              >help_outline</mat-icon
            >
          </mat-option>
        </mat-select>
        <mat-error
          class="error"
          *ngIf="proposedForm.controls['mitigation_status_id'].errors?.['required']"
          >Action status is required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Update Button----- -->
    <div class="form-dialog-conten-button-box-2">
      <div class="form-dialog-conten-button-flex">
        <!-- <div class="form-dialog-conten__button-box">
          <button
            *ngIf="!actionsControlsData.element"
            class="form-dialog-conten__button-box__btn--send"
            type="submit"
            mat-raised-button
            (click)="onAddMitigation()"
          >
            Add mitigation
          </button>
        </div> -->
        <div class="form-dialog-conten__button-box">
          <button
            class="form-dialog-conten__button-box__btn--send"
            type="submit"
            mat-raised-button
            style="width: 8rem; height: 3.2rem"
          >
            Save
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="form-dialog-content__layout">
    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >First name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="firstName" required />
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Last name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--3"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="lastName" required />
      </mat-form-field>
    </div> -->
    <!-- </div> -->
  </form>
</div>
