import { Component } from '@angular/core';

@Component({
  selector: 'app-pagenotfoundcomponent',
  templateUrl: './pagenotfoundcomponent.component.html',
  styleUrls: ['./pagenotfoundcomponent.component.scss']
})
export class PagenotfoundcomponentComponent {

}
