<!-- step-2 -->
<div class="form-container">
  <form *ngIf="filterForm" [formGroup]="filterForm" class="search">
    <!-- //Title----- -->
    <div class="sec-1">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label">Title</mat-label>

        <input
          formControlName="title"
          hideToggle="true"
          class="search__input"
          matInput
          placeholder="Search"
        />
        <button
          style="border: none; cursor: pointer; background-color: #ffffff12"
          class="search__button"
        >
          <mat-icon class="search__icon">
            <img
              class="search__icon"
              src="../../assets/shared-image/Vector-search.svg"
              alt="~/Frontend/images/icon _ search-icon"
          /></mat-icon>
        </button>
      </div>
    </div>

    <!-- //Risk category----- -->
    <div class="sec-2">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label"
          >Risk category</mat-label
        >

        <mat-select
          [multiple]="true"
          class="risk-management-form-field-box__input-3 aa"
          placeholder="All"
          formControlName="category"
        >
          <ng-container *ngFor="let riskCat of categories">
            <mat-option *ngIf="riskCat.disabled == 0" [value]="riskCat.id">
              {{ riskCat.title }}

              <mat-icon [matTooltip]="riskCat.description" matSuffix
                >help_outline</mat-icon
              >
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
    </div>

    <!-- //Created by----- -->
    <div class="sec-3">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label"
          >Created by</mat-label
        >

        <mat-select
          [multiple]="true"
          class="risk-management-form-field-box__input-3 aa"
          placeholder="All"
          formControlName="created_by"
        >
          <mat-option *ngFor="let user of riskRaiser" [value]="user.id">
            {{ user?.full_name }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <!-- //Risk owner----- -->
    <div class="sec-4">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label"
          >Risk owner</mat-label
        >

        <mat-select
          [multiple]="true"
          class="risk-management-form-field-box__input-3 aa"
          placeholder="All"
          formControlName="owner"
        >
          <mat-option *ngFor="let user of risksOwners" [value]="user.id">
            {{ user?.user?.full_name || user?.email }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <!-- //Sort----- -->
    <div class="sec-5">
      <div class="risk-management-form-field field-3">
        <mat-label class="risk-management-form-field__label">Sort</mat-label>

        <mat-select
          class="risk-management-form-field-box__input-3 aa"
          placeholder="All"
          formControlName="sort"
        >
          <mat-option>All</mat-option>
          <mat-option *ngFor="let item of sort" [value]="item.value">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <!-- //Reset Button----- -->
    <div class="sec-6">
      <button
        class="risk-management-reset-btn"
        mat-raised-button
        (click)="resetForm()"
      >
        Reset
      </button>
    </div>
  </form>
</div>
<!-- step-3 -->
<div class="risk-management-export">
  <div class="risk-management-export-box">
    <div>
      <mat-label class="initiatives-text-checkbox"
        >Display risks with targets not set</mat-label
      >
      <mat-checkbox
        labelPosition="before"
        [(ngModel)]="request_assistance"
        (ngModelChange)="risksNeedHelp()"
      ></mat-checkbox>
    </div>

    <div>
      <mat-label class="initiatives-text-checkbox"
        >Unhide redundant risks ({{
          this.AllRisk?.redundentRisk?.length
        }})</mat-label
      >
      <mat-checkbox
        labelPosition="before"
        [(ngModel)]="redundant"
        (ngModelChange)="redundantChange()"
      ></mat-checkbox>
    </div>
    <!-- //Reset Button----- -->
    <div>
      <button
        class="risk-management-export-btn"
        mat-raised-button
        (click)="export(id, initiativeId)"
      >
        <mat-icon class="risk-management-export-box-icon">
          <svg
            class="risk-management-export-box-icon--1"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
          >
            <path
              d="M5.65951 17.6484C5.15534 17.6484 4.72389 17.4772 4.36517 17.1348C4.00584 16.7918 3.82617 16.3797 3.82617 15.8984V13.2734H5.65951V15.8984H16.6595V13.2734H18.4928V15.8984C18.4928 16.3797 18.3135 16.7918 17.9548 17.1348C17.5954 17.4772 17.1637 17.6484 16.6595 17.6484H5.65951ZM11.1595 14.1484L6.57617 9.77344L7.8595 8.50469L10.2428 10.7797V3.64844H12.0762V10.7797L14.4595 8.50469L15.7428 9.77344L11.1595 14.1484Z"
              fill="white"
            /></svg
        ></mat-icon>

        Export to excel
      </button>
    </div>

    <div>
      <button
        class="risk-management-export-btn"
        mat-raised-button
        (click)="exportPDF()"
      >
        <mat-icon class="risk-management-export-box-icon">
          <svg
            class="risk-management-export-box-icon--2"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
          >
            <path
              d="M5.65951 17.6484C5.15534 17.6484 4.72389 17.4772 4.36517 17.1348C4.00584 16.7918 3.82617 16.3797 3.82617 15.8984V13.2734H5.65951V15.8984H16.6595V13.2734H18.4928V15.8984C18.4928 16.3797 18.3135 16.7918 17.9548 17.1348C17.5954 17.4772 17.1637 17.6484 16.6595 17.6484H5.65951ZM11.1595 14.1484L6.57617 9.77344L7.8595 8.50469L10.2428 10.7797V3.64844H12.0762V10.7797L14.4595 8.50469L15.7428 9.77344L11.1595 14.1484Z"
              fill="white"
            /></svg
        ></mat-icon>

        Export to pdf
      </button>
    </div>
  </div>
</div>

<!-- 
  <button
  (click)="export(initiative.id, initiative.official_code)"
  class="primaryButton mt-1"
>
  Export to excel
</button>
<button (click)="exportPdf()" class="primaryButton mt-1">
  Export to pdf
</button> -->
