<!-- user-management-content -->

<div class="user-management__icon-box">
  <mat-icon class="user-management__icon-box__icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="30"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M19 11q-.95 0-2.025-.537t-2-1.488q-.925-.95-1.462-2.025t-.538-2q0-.425.125-.775t.375-.6q.65-.65 2.638-1.138t3.737-.412q.625.05 1.038.15t.612.3q.175.175.288.537t.162.913q.125 1.725-.35 3.8t-1.15 2.75q-.25.25-.637.388T19 11Zm.875-5q.05-.45.075-.95T20 4q-.5-.025-1.012.013t-1.013.087q.275.2.537.425t.488.45q.25.25.463.5t.412.525ZM19 9q.025-.5-.375-1.238T17.575 6.4q-.625-.625-1.338-1.012T15.026 5q.05.575.425 1.3t.925 1.275q.6.6 1.313.988T19 9Zm2.3 9.7L16.6 14h-10L1.3 8.7l1.4-1.4L7.4 12h10l5.3 5.3l-1.4 1.4ZM8 22v-4q0-.825.588-1.413T10 16h4q.825 0 1.413.588T16 18v4H8Z"
        fill="black"
      /></svg
  ></mat-icon>
  <span class="user-management__icon-box__title">Phases</span>
</div>

<div class="user-management__text-box--1">
  <p class="user-management__text-box__title">
    Welcome to the phases panel, here you can manage the phases table within the
    platform.
  </p>
</div>

<div class="user-management__content--1">
  <div class="user-management__container">
    <div class="form-container">
      <form *ngIf="filterForm" [formGroup]="filterForm" class="search">
        <div class="sec-1">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Search
            </mat-label>
            <input
              formControlName="name"
              class="search__input"
              matInput
              placeholder="Search"
              #input
            />

            <button
              style="border: none; cursor: pointer; background-color: #ffffff12"
              class="search__button"
            >
              <mat-icon class="search__icon">
                <img
                  class="search__icon"
                  src="../../assets/shared-image/Vector-search.svg"
                  alt="~/Frontend/images/icon _ search-icon"
              /></mat-icon>
            </button>
          </div>
        </div>

        <!-- //Sort----- -->
        <div class="sec-6">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Sort</mat-label
            >

            <mat-select
              formControlName="sort"
              class="risk-management-form-field-box__input-3"
              [placeholder]="'All'"
              id="select-where"
            >
              <mat-option>All</mat-option>
              <mat-option *ngFor="let item of sortBtn" [value]="item.value">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- //Reset Button----- -->
        <div class="sec-9">
          <button
            class="risk-management-reset-btn"
            mat-raised-button
            (click)="resetForm()"
          >
            Reset
          </button>
        </div>

        <div class="sec-7">
          <button
            class="user-management__button-box__btn btn-primary-size"
            mat-raised-button
            color="primary"
            (click)="openDialog()"
          >
            Add new phases
          </button>
        </div>
      </form>
    </div>

    <div class="table-box mat-elevation-z8">
      <table mat-table matSort [dataSource]="dataSource">
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-1-m">Id</span>
          </th>
          <td mat-cell *matCellDef="let phase">{{ phase.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-2-m">Name</span>
          </th>
          <td mat-cell *matCellDef="let phase">
            <span class="td-3-h">{{ phase.name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="reporting_year">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-3-m">Reporting year</span>
          </th>
          <td mat-cell *matCellDef="let phase">{{ phase.reporting_year }}</td>
        </ng-container>

        <ng-container matColumnDef="start_date">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-4-m">Start date</span>
          </th>
          <td mat-cell *matCellDef="let phase">
            {{ phase.start_date | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="end_date">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-5-m">End date</span>
          </th>
          <td mat-cell *matCellDef="let phase">{{ phase.end_date | date }}</td>
        </ng-container>

        <ng-container matColumnDef="previous_phase">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-6-m">Previous Phase</span>
          </th>
          <td mat-cell *matCellDef="let phase">
            {{ phase.previous_phase ? phase.previous_phase.name : "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-7-m">Status</span>
          </th>
          <td mat-cell *matCellDef="let phase">
            <span
              [ngClass]="{
                closed: phase.status === 'closed',
                open: phase.status === 'open'
              }"
              >{{ phase.status }}</span
            >
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-8-m">Active</span>
          </th>
          <td mat-cell *matCellDef="let phase">
            {{ phase.active ? "Yes" : "No" }}
          </td>
        </ng-container> -->

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-9-m">Actions</span>
          </th>
          <td mat-cell *matCellDef="let phase">
            <div class="m">
              <button
                class="user-management-table-icon"
                *ngIf="!phase.active"
                routerLink="./{{ phase['id'] }}"
                matTooltip="Submitted versions"
              >
              <mat-icon>view_carousel</mat-icon>
              </button>
              <button
                matTooltip="Edit"
                class="user-management-table-icon"
                (click)="openDialog(phase.id)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                class="user-management-table-icon"
                *ngIf="!phase.active"
                (click)="activate(phase.id)"
                matTooltip="Phase disabled"
              >
                <mat-icon>block</mat-icon>
              </button>
              <button
                class="user-management-table-icon"
                *ngIf="phase.active"
                (click)="deactivate(phase.id)"
                matTooltip="Phase enabled"
              >
                <mat-icon>check_circle_outline</mat-icon>
              </button>
              <button
                matTooltip="Delete"
                class="user-management-table-icon"
                (click)="delete(phase.id)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
      </table>

      <mat-paginator
        class="glossary-content-container user-management-paginator-box"
        [pageSizeOptions]="[10, 20, 30, 50, 100]"
        [length]="length"
        [pageIndex]="pageIndex - 1"
        (page)="pagination($event)"
        aria-label="Select page of users"
      >
      </mat-paginator>
    </div>
  </div>
</div>
