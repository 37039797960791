<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">{{ userData.title }}</p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-conten-box-1">
  <form [formGroup]="userFormData" class="form-dialog-content">
    <!-- //Email filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >First name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="first_name" />
        <mat-error
          class="error"
          *ngIf="userFormData.controls?.first_name?.errors?.['required']"
          >first_name is required</mat-error
        >
      </mat-form-field>
    </div>

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Last name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="last_name" />
        <mat-error
          class="error"
          *ngIf="userFormData.controls?.last_name?.errors?.['required']"
          >Last name is required</mat-error
        >
      </mat-form-field>
    </div>

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Email</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="email" />
        <mat-error
          class="error"
          *ngIf="userFormData.controls?.email?.errors?.['required']"
          >email is required</mat-error
        >
        <mat-error
          class="error"
          *ngIf="userFormData.controls?.email?.errors?.['email']"
          >Valid Email is required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- <div class="form-dialog-content__layout">
    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >First name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="firstName" required />
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Last name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--3"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="lastName" required />
      </mat-form-field>
    </div> -->
    <!-- </div> -->

    <div class="form-dialog-conten-box-2">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >which role will be assigned?<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <mat-select formControlName="role">
          <mat-option value="admin"> Admin </mat-option>
          <mat-option value="user"> User </mat-option>
        </mat-select>
        <mat-error
          class="error"
          *ngIf="userFormData.controls?.role?.errors?.['required']"
          >Role is required</mat-error
        >
      </mat-form-field>
    </div>
    <div>
      <!-- //Update Button----- -->
      <div class="form-dialog-conten-button-box">
        <div class="form-dialog-conten__button-box">
          <button
            *ngIf="!userData.element"
            class="form-dialog-conten__button-box__btn"
            type="submit"
            mat-raised-button
            (click)="onSubmit()"
          >
            Add
          </button>

          <button
            *ngIf="userData.element"
            class="form-dialog-conten__button-box__btn"
            type="submit"
            mat-raised-button
            color="primary"
            (click)="onSubmit()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
