<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ announcementData.title }}
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-content">
  <form [formGroup]="announcementForm" class="form-dialog-content">
    <!-- //Email filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Subject<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="subject" required />
        <mat-error
          class="error"
          *ngIf="announcementForm.controls?.subject?.errors?.['required']"
          >Subject is required</mat-error
        >
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Message<span class="star">*</span></mat-label
        >
      </div>
      <div class="NgxEditor__Wrapper">
        <!-- <ngx-editor-menu [toolbar]="toolbar" [editor]="editor"> </ngx-editor-menu>
        <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" [placeholder]="'Type here...'" formControlName="description"></ngx-editor> -->
        <editor
          formControlName="description"
          [init]="{ base_url: '/tinymce', 
        selector: 'textarea',
        menubar:false,
        suffix: '.min',  
        invalid_elements : 'img',
        plugins: 'link fullscreen ', 
        toolbar: ' undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify  | bullist numlist outdent indent  | forecolor  |fullscreen',
      }"
        >
        </editor>
      </div>
      <div
        class="alert"
        style="color: red"
        *ngIf="
          announcementForm.controls.description.errors &&
          announcementForm.controls.description.touched
        "
      >
        <p>This is required max 1500</p>
      </div>
      <!-- <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <textarea
          matInput
          formControlName="message"
          required
          style="height: 80px; resize: none"
          cdkAutosizeMinRows="2"
          cdkAutosizeMaxRows="10"
        ></textarea>

      </mat-form-field> -->
    </div>

    <!-- //Update Button----- -->
    <div class="form-dialog-conten-button-box-2">
      <div class="form-dialog-conten-button-flex">
        <!-- <div class="form-dialog-conten__button-box">
          <button
            *ngIf="!announcementData.element"
            class="form-dialog-conten__button-box__btn--send"
            type="submit"
            mat-raised-button
            (click)="onSendAnnouncement()"
          >
            Send
          </button>
        </div> -->

        <div class="form-dialog-conten__button-box">
          <button
            *ngIf="!announcementData.element"
            class="form-dialog-conten__button-box__btn--save"
            type="submit"
            mat-raised-button
            (click)="onSubmit()"
          >
            Save
          </button>
        </div>
      </div>
      <div class="form-dialog-conten__button-box">
        <button
          *ngIf="announcementData.element"
          class="form-dialog-conten__button-box__btn--send"
          type="submit"
          mat-raised-button
          (click)="onSubmit()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- <div class="form-dialog-content__layout">
    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >First name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="firstName" required />
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Last name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--3"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="lastName" required />
      </mat-form-field>
    </div> -->
    <!-- </div> -->
  </form>
</div>
