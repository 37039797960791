<mat-card class="result">
  <mat-card-content>
    <h2 class="example-h2">Submit</h2>

    <section class="example-section">
      <mat-slide-toggle
        class="example-margin"
        [checked]="canPublish"
        (change)="toggle()"
      >
        Allow submit system
      </mat-slide-toggle>
    </section>
  </mat-card-content>
</mat-card>

<div class="table-content">
  <h2 class="title">Constants Table</h2>
  <div class="table-box mat-elevation-z8">
    <table mat-table [dataSource]="constants">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- User ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <span class="h1 th-1-m">ID</span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <!-- User name Column -->
      <ng-container matColumnDef="lable">
        <th mat-header-cell *matHeaderCellDef>
          <span class="th-2-m">Label</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="td-3-h">{{ element.label }}</span>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>
          <span class="h3">Value</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="td-h">{{ element.value }}</span>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          <span class="h5">Action</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="m">
            <mat-icon
              class="edit-icon"
              matTooltip="Edit"
              (click)="editConstant(element)"
              >edit</mat-icon
            >
          </div>
        </td>
      </ng-container>

      <tr
        class="table-header"
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="table-header"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>
</div>
