<!-- user-management-content -->

<div class="user-management__icon-box">
  <mat-icon class="email-icon"> email</mat-icon>
  <span class="user-management__icon-box__title">Emails</span>
</div>

<div class="user-management__text-box--1">
  <p class="user-management__text-box__title">
    Welcome to the emails panel, here you can view the emails sent to the user
    within the platform
  </p>
</div>

<div class="user-management__content--1">
  <div class="user-management__container">
    <div class="form-container">
      <form *ngIf="formData" [formGroup]="formData" class="search">
        <div class="sec-1">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Search
            </mat-label>
            <input
              class="search__input"
              matInput
              type="text"
              formControlName="search"
              placeholder="Search"
            />

            <button
              style="border: none; cursor: pointer; background-color: #ffffff12"
              class="search__button"
            >
              <mat-icon class="search__icon">
                <img
                  class="search__icon"
                  src="../../assets/shared-image/Vector-search.svg"
                  alt="~/Frontend/images/icon _ search-icon"
              /></mat-icon>
            </button>
          </div>
        </div>

        <div class="sec-5">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Filter Status</mat-label
            >

            <mat-select
              formControlName="status"
              panelClass="x"
              class="risk-management-form-field-box__input-3"
              [placeholder]="'All'"
              id="select-where"
            >
              <mat-option value="All">All</mat-option>
              <mat-option value="true">Sent</mat-option>
              <mat-option value="false"> Not Sent</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="sec-6">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Sort</mat-label
            >

            <mat-select
              formControlName="sort"
              panelClass="x"
              class="risk-management-form-field-box__input-3"
              [placeholder]="'All'"
              id="select-where"
            >
              <mat-option>All</mat-option>
              <mat-option *ngFor="let item of sort" [value]="item.value">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <!-- //Reset Button----- -->
        <div class="sec-9">
          <button
            class="risk-management-reset-btn"
            mat-raised-button
            (click)="resetForm()"
          >
            Reset
          </button>
        </div>
      </form>
    </div>

    <div class="table-box mat-elevation-z8">
      <table mat-table [dataSource]="emailLogs">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- User ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h1 th-1-m">ID</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <!-- User name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-2-m">Name</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="td-3-h">{{ element.name }}</span>
          </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h3">Subject</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="td-h">{{ element.subject }}</span>
          </td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h4">Email</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h4">Status</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="pending" *ngIf="element.status == 0">Not sent</span>
            <span class="approved" *ngIf="element.status == 1">Sent</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h4">Created At</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ this.pipe.transform(element.createdAt, "short") }}
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h5">Action</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="m">
              <button
                *ngIf="visable == false"
                class="user-management-table-icon"
                (click)="openShowEmailBodyDialog(element.emailBody)"
              >
                <svg
                  style="margin-right: 0.5em"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  class="bi bi-eye"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                  />
                  <path
                    d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                  />
                </svg>
              </button>
            </div>
          </td>
        </ng-container>

        <tr
          class="table-header"
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          class="table-header"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <div class="glossary-content-container user-management-paginator-box">
        <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageChanged($event)"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
