<!-- <div class="user-management__main__text-box--1">
  <div>
    <p class="user-management__main__text-box__title--1">All users</p>
  </div>

  <div>
    <p class="user-management__main__text-box__title--2">4 total</p>
  </div>
</div> -->

<div class="user-management__container">
  <div class="user-management__search-box-flex">
    <div class="user-management__search-box">
      <!-- <form action="" class="search">
        <input
          class="search__input"
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          #input
        />

        <button
          style="border: none; cursor: pointer; background-color: #ffffff12"
          class="search__button"
        >
          <mat-icon class="search__icon">
            <img
              class="search__icon"
              src="../../assets/shared-image/Vector-search.svg"
              alt="~/Frontend/images/icon _ search-icon"
          /></mat-icon>
        </button>
      </form> -->
    </div>
    <div class="user-management__button-box">
      <button
        style="width: 230px"
        class="parameters-settings__button-box__btn btn-primary-size"
        (click)="openDialogCreateAnnouncement('Create new announcement')"
        mat-raised-button
        color="primary"
      >
        Create new announcement
      </button>
    </div>
  </div>

  <!-- <div class="posted-scroll-box mat-elevation-z8"> -->

  <div
    class="announcement-box"
    *ngFor="let announcement of postedAnnouncements"
  >
    <div class="announcement-box__1">
      <p class="announcement-box__1--title">
        {{ announcement.sendDate | date : "medium" }}
        <!-- <span class="announcement-box__1--title--1">{{
          announcement.time
        }}</span> -->
      </p>
      <mat-icon style="width: 5px; height: 30px" class="color-change">
        <img
          class="table__icon"
          src="../../../../../assets/shared-image/Line 93.svg"
          alt="~/Frontend/images/icon _ Vector-edit"
        />
      </mat-icon>
    </div>
    <div class="announcement-box__2">
      <p class="announcement-box__2--title-2">
        <span class="announcement-box__2--title-3">{{
          announcement.subject
        }}</span>
        -
        <span
          [innerHTML]="
            announcement.description | safeHtml : 'safeHtml for announcement'
          "
        ></span>
      </p>
    </div>
  </div>
  <!-- </div> -->
</div>
