<div class="form-dialog-header">
  <div class="form-dialog-header__text-box">
    <p class="form-dialog-header__text-box__text">
      {{ mitigationData.action }}
    </p>
  </div>

  <div class="form-dialog-header__icon-box">
    <button class="form-dialog-header__icon-box__btn" (click)="onCloseDialog()">
      <mat-icon class="form-dialog-header__icon-box__icon-size">
        <img
          class="form-dialog-header__icon-box__icon-size__icon"
          src="../../../assets/shared-image/Vector-close.svg"
          alt="~/Frontend/images/icon _ Vector-close"
        />
      </mat-icon>
    </button>
  </div>
</div>

<div class="form-dialog-content">
  <form [formGroup]="mitigationForm" class="form-dialog-content">
    <!-- //Email filed----- -->

    <div class="form-dialog-content__field--grid-column">
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Status<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input matInput formControlName="title" required />
        <mat-error
          class="error"
          *ngIf="mitigationForm.controls?.title?.errors?.['required']"
          >Status of Action is required</mat-error
        >
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Actions/Controls description<span class="star">*</span></mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <textarea matInput formControlName="description" required></textarea>
        <mat-error
          class="error"
          *ngIf="mitigationForm.controls?.description?.errors?.['required']"
          >Description is required</mat-error
        >
      </mat-form-field>
    </div>

    <!-- //Update Button----- -->
    <div class="form-dialog-conten-button-box-2">
      <div class="form-dialog-conten__button-box">
        <button
          *ngIf="!mitigationData.element"
          class="form-dialog-conten__button-box__btn"
          type="submit"
          mat-raised-button
          (click)="onSubmit()"
        >
          Add
        </button>
      </div>
      <div class="form-dialog-conten__button-box">
        <button
          *ngIf="mitigationData.element"
          class="form-dialog-conten__button-box__btn"
          type="submit"
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
        >
          Update
        </button>
      </div>
    </div>

    <!-- <div class="form-dialog-content__layout">
    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >First name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--2"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="firstName" required />
      </mat-form-field>
    </div>

    <div>
      <div class="form-dialog-content__title-box">
        <mat-label class="form-dialog-content__title-box__title"
          >Last name</mat-label
        >
      </div>
      <mat-form-field
        class="form-dialog-content__field--3"
        floatLabel="always"
        hideRequiredMarker
        appearance="outline"
      >
        <input type="text" matInput formControlName="lastName" required />
      </mat-form-field>
    </div> -->
    <!-- </div> -->
  </form>
</div>
