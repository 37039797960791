<!-- user-management-content -->

<div class="user-management__icon-box">
  <mat-icon class="user-management__icon-box__icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="30"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M20 4H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM4 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H4Zm2 5h2v2H6V7Zm5 0a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Zm-3 4H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Zm-2 3H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Z"
        clip-rule="evenodd"
        fill="black"
      /></svg
  ></mat-icon>
  <span class="user-management__icon-box__title">Glossary</span>
</div>

<div class="user-management__text-box--1">
  <p class="user-management__text-box__title">
    Welcome to the glossary panel, here you can manage the glossary table within
    the platform.
  </p>
</div>

<div class="user-management__content--1">
  <div class="user-management__container">
    <div class="form-container">
      <form *ngIf="filterForm" [formGroup]="filterForm" class="search">
        <div class="sec-1">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Search
            </mat-label>
            <input
              formControlName="title"
              class="search__input"
              matInput
              placeholder="Search"
              #input
            />

            <button
              style="border: none; cursor: pointer; background-color: #ffffff12"
              class="search__button"
            >
              <mat-icon class="search__icon">
                <img
                  class="search__icon"
                  src="../../assets/shared-image/Vector-search.svg"
                  alt="~/Frontend/images/icon _ search-icon"
              /></mat-icon>
            </button>
          </div>
        </div>

        <!-- //Sort----- -->
        <div class="sec-6">
          <div class="risk-management-form-field field-3">
            <mat-label class="risk-management-form-field__label"
              >Sort</mat-label
            >

            <mat-select
              formControlName="sort"
              class="risk-management-form-field-box__input-3"
              [placeholder]="'All'"
              id="select-where"
            >
              <mat-option>All</mat-option>
              <mat-option *ngFor="let item of sort" [value]="item.value">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <!-- //Reset Button----- -->
        <div class="sec-9">
          <button
            class="risk-management-reset-btn"
            mat-raised-button
            (click)="resetForm()"
          >
            Reset
          </button>
        </div>

        <div class="sec-7">
          <button
            class="parameters-settings__button-box__btn btn-primary-size"
            (click)="openFormDialog('', 'Add')"
            mat-raised-button
            color="primary"
          >
            Create Glossary
          </button>
        </div>
      </form>
    </div>

    <div class="table-box mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h1">ID</span>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <span class="th-3">Title</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span class="td-3-h">{{ element.title }}</span>
          </td>
        </ng-container>

        <!-- Description group Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h3">Description</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span
              class="td-h"
              [innerHTML]="element.description | faqPipe"
            ></span>
          </td>
        </ng-container>

        <!-- Action Name Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <span class="h5">Actions</span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="m">
              <button
                matTooltip="Edit"
                style="border: none; cursor: pointer; background-color: #ffffff"
                (click)="editGlossary(element.id, 'Edit')"
              >
                <mat-icon class="user-management-table__icon">
                  <img
                    class="table__icon"
                    src="../../assets/shared-image/Vector-edit.svg"
                    alt="~/Frontend/images/icon _ Vector-edit"
                /></mat-icon>
              </button>

              <button
                matTooltip="Delete"
                style="border: none; cursor: pointer; background-color: #ffffff"
                (click)="deleteGlossary(element)"
              >
                <mat-icon class="user-management-table__icon">
                  <img
                    class="table__icon"
                    src="../../assets/shared-image/Vector-delete.svg"
                    alt="~/Frontend/images/icon _ Vector-delete"
                /></mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr
          class="table-header"
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          class="table-header"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        class="glossary-content-container user-management-paginator-box"
        [pageSizeOptions]="[10, 20, 30, 50, 100]"
        [length]="length"
        [pageIndex]="pageIndex - 1"
        (page)="pagination($event)"
        aria-label="Select page of users"
      >
      </mat-paginator>
    </div>
  </div>
</div>
