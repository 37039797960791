<section class="section-glossary">
  <div class="glossary-box">
    <!-- step--1 -->
    <div class="glossary-title-box">
      <p class="glossary-title-box__title">GLOSSARY</p>
    </div>

    <!-- step--2 -->

    <div class="glossary-main-content">
      <div class="glossary-content-container">
        <div class="glossary-search-box">
          <form class="search" [formGroup]="form">
            <input
              formControlName="title"
              class="search__input"
              matInput
              placeholder="Search by term"
              #input
            />
            <button
              style="border: none; background-color: #ffffff12"
              class="search__button"
            >
              <mat-icon class="search__icon">
                <img
                  class="search__icon"
                  src="../../assets/shared-image/Vector-search.svg"
                  alt="~/Frontend/images/icon _ search-icon"
              /></mat-icon>
            </button>
          </form>

          <!-- <div class="glossary-text-search-box">
            <p class="glossary-text-search-box__text">Filters:</p>
            <div>
              <mat-chip-grid #chipGrid aria-label="Fruit selection">
                <mat-chip-row
                  *ngFor="let term of glossary"
                  (removed)="remove(term)"
                >
                  {{ term }}
                  <button matChipRemove [attr.aria-label]="'remove ' + term">
                    <mat-icon style="color: #04030f">cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-grid>
            </div>
          </div> -->
        </div>

        <!-- step--2 -->
      </div>
      <nav class="glossary-nav">
        <ul class="glossary-nav__list">
          <li *ngFor="let char of alphabet" class="glossary-nav__list--item">
            <a
              (click)="showPhase(char.character)"
              (click)="setCharValue(char.value)"
              [class.glossary-link-active]="activeButton === char.character"
              class="glossary-link m-t"
            >
              {{ char.character }}
            </a>
          </li>
        </ul>
      </nav>

      <!-- step--3 -->
      <div class="glossary-matching-records glossary-content-container">
        <p class="glossary-matching-records__text">
          Showing
          <span class="glossary-matching-records__text--1">{{
            data?.count
          }}</span>
          matching records
        </p>
      </div>

      <!-- step--4 -->
      <div class="glossary-show-records-box">
        <div
          class="glossary-show-records glossary-content-container-1"
          *ngFor="let term of glossary"
        >
          <p class="glossary-show-records__text">{{ term.title }}</p>
          <p class="glossary-show-records__text--1" [innerHTML]="term.description | safeHtml : 'safeHtml for mitigation'">
          </p>
        </div>
      </div>

      <div style="text-align: center" class="glossary-content-container">
        <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex - 1"
          (page)="pagination($event)"
          [hidePageSize]="true"
          aria-label="Select page"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</section>
